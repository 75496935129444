import React, {Fragment} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import ErrorPage from "./pages/404Error";
import ContactPage from "./pages/contact";
import ServicePage from "./pages/service";
import HomeDefault from "./pages/home/HomeDefault";
import PortfolioDetailsPage from "./pages/portfolio/details";
import "./provider/i18n";

const App = () => {
     return (
              <Fragment>
                     <Router>
                            <Switch>
                                   <Route path={`${process.env.PUBLIC_URL + "/contact"}`}
                                          component={ContactPage}/>
                                   <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                                          component={ServicePage}/>
                                   <Route path={`${process.env.PUBLIC_URL + "/portfolio/:slug"}`}
                                          component={PortfolioDetailsPage}/>
                                   <Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                                          component={HomeDefault}/>
                                   <Route exact component={ErrorPage}/>
                            </Switch>
                     </Router>
              </Fragment>
     );
};

export default App;